var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { color, alignItems, display, flexDirection, justifyContent, } from 'styled-system';
import GradientBlock, { GradientBlockType, } from '../../common/components/GradientBlock';
import { FlexJustifyContent, Grid, GridItem, } from '../../common/components/Grid';
var NewsletterSignupBackground = styled(GradientBlock)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
NewsletterSignupBackground.defaultProps = __assign(__assign({}, GradientBlock.defaultProps), { flexDirection: 'column', type: GradientBlockType.Vertical, backgroundColor: 'sys.neutral.background.default' });
var NewsletterSignupGrid = styled(Grid)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), color, justifyContent);
NewsletterSignupGrid.defaultProps = {
    justifyContent: FlexJustifyContent.CENTER,
    bg: 'transparent',
    px: 'sp_0',
    py: 'sp_0',
};
var NewsletterSignupGridItem = styled(GridItem)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  box-sizing: border-box;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  box-sizing: border-box;\n"])), display, flexDirection, justifyContent, alignItems);
NewsletterSignupGridItem.defaultProps = {
    display: 'flex',
    flexDirection: ['column', null, 'row'],
    justifyContent: ['auto', 'space-between'],
    alignItems: ['center', null, 'flex-start'],
    py: ['sp_20', 'sp_24', 'sp_12', 'sp_20'],
    px: ['sp_16', 'sp_24', 'sp_32', 'sp_64'],
    w: 1,
};
export var NewsletterContainer = function (_a) {
    var children = _a.children, startColor = _a.startColor, endColor = _a.endColor, borderTop = _a.borderTop;
    return (React.createElement(NewsletterSignupBackground, { startColor: startColor, endColor: endColor, borderTop: borderTop },
        React.createElement(NewsletterSignupGrid, null,
            React.createElement(NewsletterSignupGridItem, null, children))));
};
export default NewsletterContainer;
var templateObject_1, templateObject_2, templateObject_3;
