var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from '@emotion/styled';
import { space } from 'styled-system';
import { linkStyles } from '../../common/components/Link';
import { Text } from '../../common/components/Text';
export var CommonText = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > p {\n    ", ";\n  }\n\n  & a {\n    ", ";\n    color: inherit;\n  }\n"], ["\n  > p {\n    ", ";\n  }\n\n  & a {\n    ", ";\n    color: inherit;\n  }\n"])), space, function (props) {
    return linkStyles(__assign({ styled: true, textDecoration: 'underline' }, props));
});
CommonText.defaultProps = {
    fontSize: 'fs_18',
    fontWeight: 'bold',
    lineHeight: 'lh_24',
    textAlign: 'center',
    mt: 'sp_0',
    mb: 'sp_0',
    color: 'sys.neutral.text.default',
};
var templateObject_1;
