var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { SubscriptionTypeAnon } from 'graphql-ts-types';
import { alignItems, display, flexWrap, justifyContent } from 'styled-system';
import { GridItem } from '../../common/components/Grid';
import NewsletterSignupInput from '../../common/components/NewsletterSignupInput';
import { NewsletterContainer } from './NewsletterContainer';
import { NewsletterContent } from './NewsletterContent';
import NewsletterDisclaimer from './NewsletterDisclaimer';
var InputContainerGrid = styled(GridItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, alignItems, flexWrap, justifyContent);
InputContainerGrid.defaultProps = {
    width: [1, 6 / 8, 5 / 12],
    pt: ['sp_8', 'sp_16', 'sp_0'],
    pr: ['sp_8', 'sp_12', 'sp_0'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['center', null, 'flex-start'],
    flexWrap: 'wrap',
};
var NewsletterSignup = function (_a) {
    var headline = _a.headline, subline = _a.subline, asteriskText = _a.asteriskText, tracking = _a.tracking, textColor = _a.textColor, icon = _a.icon, buttonKind = _a.buttonKind, newsletterType = _a.newsletterType, containerProps = __rest(_a, ["headline", "subline", "asteriskText", "tracking", "textColor", "icon", "buttonKind", "newsletterType"]);
    return (React.createElement(NewsletterContainer, __assign({}, containerProps),
        React.createElement(NewsletterContent, { icon: icon, headline: headline, subline: subline, textColor: textColor }),
        React.createElement(InputContainerGrid, null,
            React.createElement(NewsletterSignupInput, { tracking: tracking, buttonKind: buttonKind, newsletterType: newsletterType }),
            React.createElement(NewsletterDisclaimer, { withFixedHeight: !!icon, asteriskText: asteriskText, textColor: textColor }))));
};
NewsletterSignup.defaultProps = {
    newsletterType: SubscriptionTypeAnon.Newsletter,
};
export default NewsletterSignup;
var templateObject_1;
