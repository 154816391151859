var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, height, fontSize } from 'styled-system';
import { Button } from '../../common/components/Button';
import { useLocalization } from '../../common/providers/LocaleProvider';
import { breakpoint, textWithEllipsis } from '../../common/theme/helper';
import { textDecoration } from '../../common/theme/system-utilities';
import { CommonText } from './CommonText';
var LINES_COUNT_COLLAPSED_S = 3;
var LINES_COUNT_COLLAPSED_L = 2;
var DisclaimerExpanded = styled(CommonText)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
DisclaimerExpanded.defaultProps = __assign(__assign({}, CommonText.defaultProps), { fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_20', null, null, 'lh_24'], fontWeight: 'normal', textAlign: ['center', null, 'left'], mt: ['sp_20', null, 'sp_12', 'sp_12'] });
var DisclaimerCollapsed = styled(DisclaimerExpanded)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", "\n"], ["\n  ", ";\n  ", "\n"])), height, function (props) { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    ", ";\n    ", " {\n      ", ";\n    }\n  "], ["\n    ", ";\n    ", " {\n      ", ";\n    }\n  "])), textWithEllipsis(LINES_COUNT_COLLAPSED_S), breakpoint(1)(props), textWithEllipsis(LINES_COUNT_COLLAPSED_L)); });
var ShowMoreButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), color, textDecoration, fontSize);
ShowMoreButton.defaultProps = __assign(__assign({}, Button.defaultProps), { color: 'sys.neutral.text.strong', textDecoration: 'underline', fontSize: ['fs_14', null, null, 'fs_16'] });
var useShowExpandButton = function (asteriskText) {
    var containerRef = useRef();
    var _a = __read(useState(false), 2), isExpandButtonShown = _a[0], setIsExpandButtonShown = _a[1];
    useEffect(function () {
        setIsExpandButtonShown(containerRef.current.offsetHeight < containerRef.current.scrollHeight);
    }, [asteriskText]);
    return { isExpandButtonShown: isExpandButtonShown, containerRef: containerRef };
};
var NewsletterDisclaimer = function (_a) {
    var asteriskText = _a.asteriskText, textColor = _a.textColor, withFixedHeight = _a.withFixedHeight;
    var t = useLocalization().t;
    var _b = useShowExpandButton(asteriskText), isExpandButtonShown = _b.isExpandButtonShown, containerRef = _b.containerRef;
    var _c = __read(useState(false), 2), isExpanded = _c[0], setIsExpanded = _c[1];
    var toggleExpansion = useCallback(function () {
        setIsExpanded(function (current) { return !current; });
    }, [isExpanded]);
    var htmlContent = withFixedHeight ? asteriskText : "<p>".concat(asteriskText, "</p>");
    var Disclaimer = isExpanded ? DisclaimerExpanded : DisclaimerCollapsed;
    return (React.createElement(React.Fragment, null,
        React.createElement(Disclaimer, { ref: containerRef, color: textColor, height: withFixedHeight ? [84, null, 55, 64] : 'auto', dangerouslySetInnerHTML: {
                __html: htmlContent,
            } }),
        isExpandButtonShown && (React.createElement(ShowMoreButton, { kind: "bare", onClick: toggleExpansion, color: textColor }, t(isExpanded ? 'h24_show_less' : 'h24_show_more')))));
};
export default NewsletterDisclaimer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
