var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, color, display, flexDirection, space, textAlign, width, } from 'styled-system';
import { GridItem } from '../../common/components/Grid';
import { ResponsiveLazyImageMemo } from '../../common/components/LazyImage';
import { getCMSTrackerProps } from '../../common/tracking/getTrackerProps';
import { CommonText } from './CommonText';
var VoucherContainerGrid = styled(GridItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, flexDirection, alignItems, color);
VoucherContainerGrid.defaultProps = {
    width: [1, null, 7 / 12],
    display: 'flex',
    flexDirection: ['column', null, 'row'],
    alignItems: 'center',
};
var TextContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, textAlign);
TextContainer.defaultProps = {
    textAlign: 'center',
    my: 'sp_0',
    mx: 'auto',
};
var Headline = styled(CommonText)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
Headline.defaultProps = __assign(__assign({}, CommonText.defaultProps), { color: 'sys.neutral.text.strong', mb: 'sp_8' });
var Message = styled(CommonText)(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
Message.defaultProps = __assign(__assign({}, CommonText.defaultProps), { fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_20', null, null, 'lh_24'], fontWeight: 'normal' });
var Icon = styled(ResponsiveLazyImageMemo)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  flex-shrink: 0;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  flex-shrink: 0;\n"])), display, width, space);
Icon.defaultProps = {
    display: ['none', null, 'flex'],
    width: [null, null, '144px', '192px'],
    mr: [null, null, 'sp_24', 'sp_32'],
};
var getSublineTrackingProps = function (subline) {
    return getCMSTrackerProps({
        dangerouslySetInnerHTML: { __html: subline },
    }, {
        event: 'footerTracking',
        eventData: {
            placement: 'newsletterSignup',
        },
    }, ['A']);
};
export var NewsletterContent = function (_a) {
    var icon = _a.icon, textColor = _a.textColor, headline = _a.headline, subline = _a.subline;
    return (React.createElement(VoucherContainerGrid, null,
        icon && React.createElement(Icon, __assign({}, icon)),
        React.createElement(TextContainer, null,
            React.createElement(Headline, { color: textColor, dangerouslySetInnerHTML: { __html: headline } }),
            React.createElement(Message, __assign({ color: textColor }, getSublineTrackingProps(subline))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
